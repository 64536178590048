import { createGlobalStyle } from "styled-components";
import reset from "styled-reset";

const GlobalStyles = createGlobalStyle`
  ${reset}

  @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@900&display=swap');

  body {
    font-family: 'Roboto', sans-serif, bold;
  }
  /* Add other global styles if needed */
`;

export default GlobalStyles;
