import React from "react";
import LandingPage from "./LandingPage";
import GlobalStyle from "./GlobalStyle";
import UserContext from "./UserContext";

function App() {
  const user = {
    name: "Christian King",
    profession: "AI Researcher",
  };

  return (
    <UserContext.Provider value={user}>
      <GlobalStyle />
      <LandingPage />
    </UserContext.Provider>
  );
}

export default App;
